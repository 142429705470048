<script>
import { formatDate, createDate } from '/~/utils/format/date'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'purchase-checkout-payment-failed',
  components: {
    BaseIcon,
    BaseButton,
  },
  setup() {
    return {
      formatDate,
      createDate,
    }
  },
}
</script>

<template>
  <div
    class="h-full w-full rounded-t-3xl bg-eonx-neutral-50 sm:mx-6 sm:pt-[30px]"
  >
    <div
      class="mx-auto h-full w-full max-w-lg bg-white sm:rounded-t-3xl sm:pt-[35px]"
    >
      <div
        class="flex h-full flex-col justify-between bg-white px-5 pt-10 sm:px-10 sm:pt-0"
      >
        <div>
          <div class="mt-5 flex justify-center">
            <div
              class="flex h-20 w-20 shrink-0 items-center justify-center rounded-full bg-red-700 text-center text-white"
            >
              <base-icon svg="v2/heroic/x" :size="56" />
            </div>
          </div>
          <div
            class="mt-5 text-center text-3xl font-bold text-eonx-neutral-800"
          >
            Payment Failed
          </div>
          <div
            class="mt-2.5 text-center font-bold leading-relaxed text-eonx-neutral-600"
          >
            {{ formatDate('daymonthyearlongtime', createDate()) }}
          </div>
          <div class="mt-5 text-center text-eonx-neutral-600">
            <div v-for="(error, index) in $route.query.errors" :key="index">
              {{ error }}
            </div>
            <div class="mt-5">
              {{ $route.query.message }}
            </div>
          </div>
        </div>
        <div class="sticky bottom-0 -mx-5 block bg-white px-5 py-6 sm:-mx-10">
          <div class="flex w-full space-x-6">
            <base-button class="flex-1" @click="$router.back()">
              Go Back
            </base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
